exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-founders-js": () => import("./../../../src/pages/about/founders.js" /* webpackChunkName: "component---src-pages-about-founders-js" */),
  "component---src-pages-about-logo-js": () => import("./../../../src/pages/about/logo.js" /* webpackChunkName: "component---src-pages-about-logo-js" */),
  "component---src-pages-about-mission-js": () => import("./../../../src/pages/about/mission.js" /* webpackChunkName: "component---src-pages-about-mission-js" */),
  "component---src-pages-about-vision-js": () => import("./../../../src/pages/about/vision.js" /* webpackChunkName: "component---src-pages-about-vision-js" */),
  "component---src-pages-academics-high-js": () => import("./../../../src/pages/academics/high.js" /* webpackChunkName: "component---src-pages-academics-high-js" */),
  "component---src-pages-academics-team-js": () => import("./../../../src/pages/academics/team.js" /* webpackChunkName: "component---src-pages-academics-team-js" */),
  "component---src-pages-admissions-admission-js": () => import("./../../../src/pages/admissions/admission.js" /* webpackChunkName: "component---src-pages-admissions-admission-js" */),
  "component---src-pages-admissions-enquiry-js": () => import("./../../../src/pages/admissions/enquiry.js" /* webpackChunkName: "component---src-pages-admissions-enquiry-js" */),
  "component---src-pages-admissions-fee-js": () => import("./../../../src/pages/admissions/fee.js" /* webpackChunkName: "component---src-pages-admissions-fee-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-careers-candidate-js": () => import("./../../../src/pages/careers/candidate.js" /* webpackChunkName: "component---src-pages-careers-candidate-js" */),
  "component---src-pages-careers-positions-js": () => import("./../../../src/pages/careers/positions.js" /* webpackChunkName: "component---src-pages-careers-positions-js" */),
  "component---src-pages-circulars-js": () => import("./../../../src/pages/circulars.js" /* webpackChunkName: "component---src-pages-circulars-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-campus-js": () => import("./../../../src/pages/infrastructure/campus.js" /* webpackChunkName: "component---src-pages-infrastructure-campus-js" */),
  "component---src-pages-infrastructure-library-js": () => import("./../../../src/pages/infrastructure/library.js" /* webpackChunkName: "component---src-pages-infrastructure-library-js" */),
  "component---src-pages-infrastructure-sports-js": () => import("./../../../src/pages/infrastructure/sports.js" /* webpackChunkName: "component---src-pages-infrastructure-sports-js" */),
  "component---src-pages-life-alumni-js": () => import("./../../../src/pages/life/alumni.js" /* webpackChunkName: "component---src-pages-life-alumni-js" */),
  "component---src-pages-life-clubs-js": () => import("./../../../src/pages/life/clubs.js" /* webpackChunkName: "component---src-pages-life-clubs-js" */),
  "component---src-pages-life-events-js": () => import("./../../../src/pages/life/events.js" /* webpackChunkName: "component---src-pages-life-events-js" */),
  "component---src-pages-life-prefect-js": () => import("./../../../src/pages/life/prefect.js" /* webpackChunkName: "component---src-pages-life-prefect-js" */),
  "component---src-pages-life-social-js": () => import("./../../../src/pages/life/social.js" /* webpackChunkName: "component---src-pages-life-social-js" */)
}

